import Loading from "components/Loading";
import Permission from "components/Permission";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { MODULE_CONFIG_UPLOAD } from "shared/constants";
import PermissionDeniedPage from "components/PermissionDenied";

const ConfigUpload = React.lazy(
  () => import("containers/ServiceManagement/ConfigUpload")
);

function ConfigUploadRoute() {
  return (
    <Route
      path="config-upload"
      element={
        <Suspense fallback={<Loading loading />}>
          <Permission
            children={<ConfigUpload />}
            permissionDenied={<PermissionDeniedPage />}
            modules={[MODULE_CONFIG_UPLOAD]}
          />
        </Suspense>
      }
    />
  );
}

export default ConfigUploadRoute;
