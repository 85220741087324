import { language } from "shared/language";

export const CAPACITY = "CAPACITY";
export const LIMIT = "LIMIT";

export const TYPE_BOX = [
  {
    id: 1,
    name: language.capacity,
    selected: CAPACITY,
  },
  {
    id: 2,
    name: language.file_type,
    selected: LIMIT,
  },
];

export const DEFAULT_FILE_EXTENSION = ["png", "jpg", "jpeg", "xlsx", "xls"];